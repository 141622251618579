import { useState } from "react";
import { contractAbi, contractAddress } from "../../constants";

export const ClaimButton = ({
  minDexoClaimAmount,
  earnings,
  user,
  moralis,
  onSetEarnings,
  onFlash,
}) => {
  const [claimPending, setClaimPending] = useState(false);
  const [claimToStakePending, setClaimToStakePending] = useState(false);

  async function claimRewards() {
    setClaimPending(true);
    const tempEarnings = earnings;
    onSetEarnings(-2);
    const fn = {
      contractAddress,
      functionName: "withdrawUserReward",
      abi: contractAbi,
      params: {
        userAddress: user.attributes.ethAddress,
      },
    };
    try {
      const claimRewardsRes = await moralis.executeFunction(fn);
      await claimRewardsRes.wait();
      onFlash({
        message: "Reward claimed successfully. Check your wallet.",
        type: "success",
      });
      onSetEarnings(0);
    } catch (error) {
      onFlash({ message: "Something went wrong", type: "danger" });
      onSetEarnings(tempEarnings);
    } finally {
      setClaimPending(false);
    }
  }

  const claimAndStake = async () => {
    setClaimToStakePending(true);
    const tempEarnings = earnings;
    onSetEarnings(-2);
    const fn = {
      contractAddress,
      functionName: "withdrawUserRewardAndStake",
      abi: contractAbi
    };
    try {
      const claimRewardsRes = await moralis.executeFunction(fn);
      await claimRewardsRes.wait();
      onFlash({
        message: "Reward staked successfully. Check the staking tab in a few minutes.",
        type: "success",
      });
      onSetEarnings(0);
    } catch (error) {
      onFlash({ message: "Something went wrong", type: "danger" });
      onSetEarnings(tempEarnings);
    } finally {
      setClaimToStakePending(false);
    }
  };

  return (
    <div className="grouped-buttons">
      <button
        className={`sbtn ${claimPending || earnings < minDexoClaimAmount ? "sbtn-disabled" : "sbtn-warning"}`}
        onClick={() => claimToStakePending || earnings < minDexoClaimAmount ? null : claimRewards()}
      >
        {claimToStakePending ? "Processing..." : "Claim Rewards"}
        <div className="reward-note">Min. Amount: ${minDexoClaimAmount}</div>
      </button>
      <button
        className={`sbtn ${claimToStakePending || earnings < 10 ? "sbtn-disabled" : "sbtn-success"}`}
        onClick={() => claimToStakePending || earnings < 10 ? null : claimAndStake()}
      >
        {claimToStakePending ? "Processing..." : "Claim To Stake"}
        <div className="reward-note">Min. Amount: $10</div>
      </button>
    </div>
  );
};
