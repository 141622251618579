import React, { useEffect, useState } from 'react'
import AdminService from '../../services/admin.service'
import PackagesService from '../../services/packages.service'
import PackagesChart from './PackagesChart'
import moment from 'moment'

function AdminStats() {
  
  const [activeStats, setActiveStats] = useState ('packages')
  const [totalUsdtValue, setTotalUsdtValue] = useState (0)
  const [totalNominalValue, setTotalNominalValue] = useState (0)
  const [totalCount, setTotalCount] = useState (0)
  const [usersCount, setUsersCount] = useState (0)
  const [chartData, setChartData] = useState ()
  const [packagesStats, setPackagesStats] = useState ({})
  const [stakingsStats, setStakingsStats] = useState ({
    stakedDexo: 0,
    stakesCount: 0,
    unstakedDexo: 0,
    unstakesCount: 0,
    dexoInterests: 0
  })
  const [stakingChartData, setStakingChartData] = useState ()

  useEffect(()=>{
    loadStats()
  },[])

  const loadStats = async () => {
    const packages = await PackagesService.getAllPackages()
    let _packages = {}
    //status === confirmed
    packages.data.map(pk=>_packages[pk.price.toString()] = {
      title: pk.title,
      by1sd: 0,
      byDexo: 0,
      byUsdtDexo: 0,
      byUsdt1sd: 0,
      totalUsdt: 0,
      totalNominal: 0,
      totalCount: 0
    })
    const stats = await AdminService.getStats()
    let _totalNominalValue = 0
    let _totalUsdtValue = 0
    let _totalCount = 0
    let _chartsData = {values: [], categories: []}
    let prevDate = ''
    for (const pkg of stats.data.packageActivations) {
      if (pkg.currency === 'onesd') _packages[pkg.packagePrice].by1sd += 1
      if (pkg.currency === 'dexo') _packages[pkg.packagePrice].byDexo += 1
      if (pkg.currency === 'usdt/onesd') _packages[pkg.packagePrice].byUsdt1sd += 1
      if (pkg.currency === 'usdt/dexo') _packages[pkg.packagePrice].byUsdtDexo += 1
      if (pkg.currency === 'usdt/onesd' || pkg.currency === 'usdt/dexo') {
        _packages[pkg.packagePrice].totalUsdt += pkg.packagePrice/2
        _totalUsdtValue += pkg.packagePrice/2
      }

      //_packages[pkg.packagePrice]. += pkg.packagePrice
      _packages[pkg.packagePrice].totalCount++
      _packages[pkg.packagePrice].totalNominal += pkg.packagePrice
      _totalNominalValue += pkg.packagePrice
      _totalCount++
      const pkgDate = moment(pkg.activationDate).format('MMM YY')
      if (prevDate === pkgDate) {
        _chartsData.values[_chartsData.values.length-1] += pkg.packagePrice
      } else {
        _chartsData.values.push(pkg.packagePrice)
        _chartsData.categories.push(pkgDate)
      }
      prevDate = pkgDate
    }
    setChartData(_chartsData)
    setTotalNominalValue(_totalNominalValue)
    setTotalUsdtValue(_totalUsdtValue)
    setTotalCount(_totalCount)
    setUsersCount(stats.data.usersCount)
    setPackagesStats(_packages)
    
    let _stakedDexo = 0
    let _stakesCount = 0
    let _unstakedDexo = 0
    let _unstakesCount = 0
    let _dexoInterests = 0
    let _stakingChartData = {values: [], categories: []}
    prevDate = ''
    for (const stk of stats.data.stakings) {
      const amount = Number(stk.amount)/1000000000000000000
      if (stk.status === 'staked') {
        _stakedDexo += amount
        _stakesCount++
      }
      if (stk.status === 'unstaked') {
        const return_amount = Number(stk.return_amount)/1000000000000000000
        _unstakedDexo += amount
        _unstakesCount++
        _dexoInterests += return_amount - amount
      }
      const stkDate = moment(stk.createdAt).format('MMM YY')
      if (prevDate === stkDate) {
        _stakingChartData.values[_stakingChartData.values.length-1] += amount
      } else {
        _stakingChartData.values.push(amount)
        _stakingChartData.categories.push(stkDate)
      }
      prevDate = stkDate
    }
    setStakingsStats({
      stakedDexo: _stakedDexo,
      stakesCount: _stakesCount,
      unstakedDexo: _unstakedDexo,
      unstakesCount: _unstakesCount,
      dexoInterests: _dexoInterests
    })
    setStakingChartData(_stakingChartData)
  }

  function PackageDetails({packagesStats}) {
    let result = []
    for (const key in packagesStats) {
      const pkg = packagesStats[key]
      result.push(
        <div className='col-md-6 col-lg-4' key={key}>
          <div className='white-box pkg-stat'>
            <h3>{pkg.title}</h3> 
            <div className='pkg-stats-col'>
              <div>
                <span>By 1SD</span>
                <span style={{"color": pkg.by1sd > 0 ? "white" : "#ffffff66"}}>{pkg.by1sd}</span>
              </div>
              <div>
                <span>By DEXO</span>
                <span style={{"color": pkg.byDexo > 0 ? "white" : "#ffffff66"}}>{pkg.byDexo}</span>
              </div>
              <div>
                <span>By USDT/DEXO</span>
                <span style={{"color": pkg.byUsdtDexo > 0 ? "white" : "#ffffff66"}}>{pkg.byUsdtDexo}</span>
              </div>
              <div>
                <span>By USDT/1SD</span>
                <span style={{"color": pkg.byUsdt1sd > 0 ? "white" : "#ffffff66"}}>{pkg.byUsdt1sd}</span>
              </div>
            </div>
            <div className='pkg-stats-row'>
              <span>Total Count:</span>
              <span>{pkg.totalCount}</span>
            </div>
            <div className='pkg-stats-row'>
              <span>Total USDT Value:</span>
              <span>${pkg.totalUsdt.toLocaleString()}</span>
            </div>
            <div className='pkg-stats-row'>
              <span>Total Nominal Value:</span>
              <span>${pkg.totalNominal.toLocaleString()}</span>
            </div>
          </div>
        </div>)
    }
    return result
  }
  return <div>
    <div className='stats-tabs'>
      <div className={activeStats === 'packages' ? 'active' : ''} onClick={()=>setActiveStats('packages')}>Packages</div>
      <div className={activeStats === 'stakings' ? 'active' : ''} onClick={()=>setActiveStats('stakings')}>Stakings</div>
    </div>
    {activeStats === 'packages' ? <div>
      <div className='row tight'>
        <div className='col-md-3'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-users'></i>
            <h3>Total Users</h3>
            <div>{usersCount}</div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-boxes'></i>
            <h3>Packages Activated</h3>
            <div>{totalCount}</div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-money-bill-wave'></i>
            <h3>Nominal Value</h3>
            <div>${totalNominalValue.toLocaleString()}</div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-money-bill-wave'></i>
            <h3>USDT Value</h3>
            <div>${totalUsdtValue.toLocaleString()}</div>
          </div>
        </div>
      </div>
      <div className='row tight'>
        <PackageDetails packagesStats={packagesStats} />
      </div>
      <div className='pkg-chart'>
        {chartData ? <PackagesChart title={'Monthly Package Activation'} chartData={chartData} /> : null }
      </div>
    </div> : <div>
      <div className='row tight'>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className="fa-light fa-inbox-in"></i>
            <h3>Active Stakings</h3>
            <div>{stakingsStats.stakedDexo} DEXO ({stakingsStats.stakesCount})</div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className="fa-light fa-inbox-out"></i>
            <h3>Previously Staked</h3>
            <div>{stakingsStats.unstakedDexo} DEXO ({stakingsStats.unstakesCount})</div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='white-box pkg-total-stats'>
            <i className='fa-light fa-hand-holding-dollar'></i>
            <h3>Paid Interest</h3>
            <div>{stakingsStats.dexoInterests} DEXO</div>
          </div>
        </div>
      </div>
      <div className='pkg-chart'>
        {stakingChartData ? <PackagesChart title={'Monthly Staking'} chartData={stakingChartData} /> : null }
      </div>
    </div> }
  </div>
}

export default AdminStats;